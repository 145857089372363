/**
 * 共享服务API
 */
import * as http from '../utils/http/index.js';

const moduleName = ""

/**
 * 创建共享
 * @param {*} shareInfo 
 */
export function createShare(shareInfo){
  return http.post(`/share/addShare`, shareInfo)
}

/**
 * 查询共享信息
 * @param {*} shareId 
 */
export function getByUrl(shareId){
  return http.get(`/share/${shareId}/detail`)
}

/**
 * 检查阅读，获取阅读地址
 * @param {*} data 
 */
export function checkReader(data){
  return http.post(`/share/reader/url`, data)
}

/**
 * 扫码授权阅读，获取阅读地址
 * @param {*} ticket 
 * @param {*} docId 
 */
export function scanReader(ticket, docId){
  return http.post(`/share/scanTicket/${ticket}/reader/${docId}`)
}

/**
 * 删除共享
 */
export function remove(shareId){
  return http.del(`/share/${shareId}`)
}

/**
 * 获取我的共享
 * @param {*} query 
 */
export function getMyShare(query){
  return http.post(`/share/queryShareWithMe`, query)
}

/**
 * 获取共享给我
 * @param {*} query 
 */
export function getReceiveShare(query){
  return http.post(`/share/queryShareToMe`, query)
}

/**
 * 获取申请列表
 * @param {*} query 
 */
export function getRequestList(query){
  return http.post(`/share/request/list`, query)
}

/**
 * 申请审批
 */
export function applyMark(requestId, state){
  return http.get(`/share/request/request/${requestId}/${state}`)
}

/**
 * 获取共享目标
 * TODO 后续取消分页
 */
export function getShareTargetList(shareId){
  return http.post(`/share/${shareId}/list/targets`, {
    pageNum: 1,
    pageSize: 100
  })
}

/**
 * 添加共享目标
 * @param {*} data 
 */
export function addShareTarget(shareId, data){
  return http.post(`/share/${shareId}/add/targets`, data)
}

/**
 * 删除共享目标
 * @param {*} shareId 
 * @param {*} data 
 */
export function removeShareTarget(shareId, data){
  return http.post(`/share/${shareId}/remove/targets`, data)
}

/**
 * 订阅检查
 * @param {*} shareId 
 */
export function subscribe(shareId){
  return http.get(`/share/request/subscribeCheck/${shareId}`)
}