<template>
  <el-dialog
    title="授权管理"
    :close-on-click-modal="false" 
    :visible.sync="visible"
    width="45%">
    <el-tabs v-model="activeTabName" class="share-manager-box" >
      <el-tab-pane label="共享信息" name="about" style="height: 500px;" v-if="false">
        <el-form label-width="100px">
          <el-form-item label="共享名称">
            <el-input></el-input>
          </el-form-item>
          <el-form-item label="访问地址">
            <el-input>
              <el-button slot="append" icon="el-icon-copy-document"></el-button>
            </el-input>
          </el-form-item>
          <el-form-item label="简要介绍">
            <el-input type="textarea" :rows="5"></el-input>
          </el-form-item>
          <el-form-item label="快速共享">
            
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="授权管理" name="policy" style="height: 500px;">
        <el-row :gutter="10">
          <el-col :span="8">
            <el-table :data="targetList" size="small" border>
              <el-table-column>
                <template slot="header" slot-scope="scope">
                  <div class="flex-between">
                    <span>授权目标</span>
                    <el-button type="primary" size="mini" @click="showSelectTarget">添加目标</el-button>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div class="flex-between" @click="doSelectTarget(scope.row, scope.$index)">
                    <span>{{scope.row | showValue}}</span>
                    <span><i class="el-icon-arrow-right"></i></span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
          <el-col :span="16">
            <div v-if="selectTarget" class="flex-between" style="height: 30px; line-height: 30px; margin-bottom: 10px;">
              <span>授权目标: {{selectTarget | showValue}}</span>
              <span><el-button type="danger" size="mini" @click="removeTarget">移除授权</el-button></span>
            </div>
            <el-table :data="pList" size="small" border>
              <el-table-column
                prop="name"
                label="授权项"
                width="200">
              </el-table-column>
              <el-table-column
                prop="name"
                label="授权值">
              </el-table-column>
              <el-table-column
                prop="name"
                label="说明">
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>
    <span slot="footer" class="dialog-footer">
      <div class="flex-between">
        <el-switch v-model="open" active-text="开启共享" inactive-text="关闭共享" v-if="false"></el-switch>
        <span></span>
        <el-button type="primary" @click="visible = false">确 定</el-button>
      </div>
    </span>

    <SelectTargetDialog ref="selectTargetDialog" @onUserSelected="onUserSelected"></SelectTargetDialog>
  </el-dialog>
</template>

<script>
import {getShareTargetList, addShareTarget, removeShareTarget} from "@/api/share.js"
import SelectTargetDialog from './SelectTarget.vue'

export default {
  components: {
    SelectTargetDialog
  },
  data () {
    return {
      visible: false,
      activeTabName: "policy",
      shareId: null,
      targetList: [],
      pList: [],
      open: true,

      selectIndex: -1,
      selectTarget: null
    }
  },
  filters: {
    showValue(row){
      if(!row) return null

      if(row.type == 'TARGET_ID'){
        return row.attrs["name"]
      }else if(row.type == 'TARGET_PHONE'){
        return row.value
      }
    }
  },
  computed: {},
  watch: {},
  methods: {
    show(shareId){
      this.targetList= []
      this.visible = true
      this.shareId = shareId

      getShareTargetList(shareId).then(({code, data, message}) => {
        if(code == 0){
          this.targetList = data
          if(data.length > 0){
            this.selectTarget = data[0]
          }
        }
      })
    },
    findShareTargetList(shareId){
      getShareTargetList(shareId).then(({code, data, message}) => {
          if(code == 0){
            this.targetList = data
            if(data.length > 0){
              this.selectTarget = data[0]
            }
          }
      })
    },
    showSelectTarget(){
      this.$refs["selectTargetDialog"].visible = true
    },

    doSelectTarget(row, index){
      this.selectTarget = row
      this.selectIndex = index
    },

    //用户添加 //TODO 前端去重
    onUserSelected(item){
      addShareTarget(this.shareId, [{
        type: item.type,
        value: item.value
      }]).then(data => {
        if(data.code == 0){
          this.findShareTargetList(this.shareId);
          this.$message.success("添加目标成功!")
        }else{
          this.$message.error("添加目标失败!")
        }
      })
    },

    //移除目标
    removeTarget(){
      if(this.selectTarget){
        removeShareTarget(this.shareId, [{
          type: this.selectTarget.type,
          value: this.selectTarget.value
        }]).then(data => {
          if(data.code == 0){
            this.findShareTargetList(this.shareId);
            this.$message.success("删除目标成功!")
          }else{
            this.$message.error("删除目标失败!")
          }
        })
      }
    }
  },
  created () {

  },
  mounted () {

  },
}
</script>
<style lang='less' scoped>
.share-manager-box{
  margin-top: -30px;
}
</style>