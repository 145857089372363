<template>
  <div class="page">
    <div class="files-table">
      <!-- <div class="table-search"> -->
        <!-- <div>时间:
            <el-date-picker
              v-model="query.start"
              type="date"
              @change="changeData"
              size="medium"
              value-format="yyyy-MM-dd"
              placeholder="开始时间">
            </el-date-picker> -
            <el-date-picker
            v-model="query.end"
            type="date"
            @change="changeData"
            size="medium"
            value-format="yyyy-MM-dd"
            placeholder="结束日期">
          </el-date-picker>
        </div> -->
      <!-- </div> -->
      <!-- <el-table size="small" :data="list.list" v-loading="loading" :show-header="false">
        <el-table-column type="index" width="50">
          <template slot-scope="scope">
            <i :class="scope.row.state===0? 'el-icon-share' : 'el-icon-close'"></i>
          </template>
        </el-table-column>
        <el-table-column label="共享标题">
          <template slot-scope="scope">
            <div style="font-weight: bold;color:#009DD9;font-size:16px;cursor: pointer;" @click="goDetail(scope.row)">{{
              scope.row.name }}</div>
            <ul class="share-share">
              <li>共享日期：{{scope.row.createDate|date}}</li>
              <li class="share-line">|</li>
              <li>共享次数：{{scope.row.depth}}</li>
              <li class="share-line">|</li>
              <li>阅读次数：{{scope.row.readCount}}</li>
              <li class="share-line">|</li>
              <li>共享对象：{{scope.row.count}}</li>
            </ul>
            <el-button style="margin-top:5px;" v-show="scope.row.state===0" type='primary' plain size="small"
              @click="targets(scope.row)">权限修改</el-button>
            <el-button style="margin-top:5px;" type='primary' plain size="small" @click="addShare(scope.row)">授权共享</el-button>
            <el-button style="margin:5px 5px 0 5px;" v-show="scope.row.state===0" type='default' plain size="small"
              @click="link(scope.row)">授权链</el-button>
            <el-button style="margin:0;margin-top:5px;" :type='scope.row.state == 0 ? "danger" : "success"' plain size="small"
              @click="enableMyShare(scope.row)">{{(scope.row.state===0 ?'取消共享':'启用共享')}}</el-button>
          </template>
        </el-table-column>
        <el-table-column label="访问地址" width="100">
          <template slot-scope="scope" style="display:flex;">
            <el-button type="text" icon="iconfont icon-ico icon-color" size="medium" v-show="scope.row.state===0"
              @click="showQR(scope.row.urlShort)"></el-button>
            <el-button type="text" icon="el-icon-copy-document icon-color" size="medium" v-show="scope.row.state===0"
              @click="copyUrl(scope.row.urlShort)"></el-button>
          </template>
        </el-table-column>
      </el-table> -->
        <el-table
        v-if="favoriteTabNum == 1 && list.list.length != 0"
        size="small"
        :data="list.list"
        height="calc(100vh - 265px)"
        @row-click="handleRowClickEvent"
        style="width: 100%">
        <!-- <el-table-column
        type="index"
        width="50">
            <template slot-scope="scope">
              <i :class="scope.row.state===0? 'el-icon-share' : 'el-icon-close'"></i>
            </template>
        </el-table-column> -->
        <el-table-column
          label="共享标题"
          width="300">
            <template slot-scope="scope">
              <div class="file-box">
                  <img src="@/assets/img/meshare.png" alt="scope.row.name" lazy>
                  <span>{{scope.row.name}}</span>
              </div>
            </template>
        </el-table-column>
        <el-table-column
          prop="count"
          label="共享次数"
          align="center">
            <template slot-scope="scope">
                {{scope.row.count}}次
            </template>
        </el-table-column>
        <el-table-column
          prop="readCount"
          label="阅读次数"
          align="center">
          <template slot-scope="scope">
                {{scope.row.readCount}}次
            </template>
        </el-table-column>
        <el-table-column
          label="共享日期"
          width="150"
          align="center">
            <template slot-scope="scope">
              <span>{{scope.row.createDate|date}}</span>
            </template>
        </el-table-column>
        <el-table-column
          label="状态"
          align="center">
            <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.state === 0" effect="dark" size='small'>
                    成功
                </el-tag>
                <el-tag type="danger" v-else effect="dark" size='small'>
                    关闭
                </el-tag>
            </template>
        </el-table-column>
        <el-table-column
            prop="endTime"
            label="剩余时间"
            align="center">
             <template slot-scope="scope">
              {{ checkEndTile(scope.row.startTime,scope.row.endTime) }}
            </template>
          </el-table-column>
        <el-table-column
          label="操作"
          width="350"
          align="center">
            <template slot-scope="scope">
              <el-button v-show="scope.row.state===0&&scope.row.parentId==0" type='text' size="small" @click.stop="targets(scope.row)">权限修改</el-button>
              <!-- <el-button style="margin-top:5px;" type='primary' plain size="small" @click="addShare(scope.row)">授权共享</el-button> -->
              <el-button v-show="scope.row.state===0" type='text' size="small" @click.stop="link(scope.row)">授权链</el-button>
              <el-button type="text" :style="{'color': scope.row.state == 0 ? 'red' : 'green'}" size="small" @click.stop="enableMyShare(scope.row)">{{(scope.row.state===0 ?'取消共享':'启用共享')}}</el-button>
              <el-button type="text" size="small" v-show="scope.row.state===0" @click.stop="showQR(scope.row.urlShort)">二维码共享</el-button>
              <el-button type="text" size="small" v-show="scope.row.state===0" @click.stop="copyUrl(scope.row.urlShort)">链接共享</el-button>
            </template>
        </el-table-column>
      </el-table>

      <el-row :gutter="20" v-else>
          <template v-if="list.list.length > 0">
              <el-col :span="5" style="margin-bottom: 15px;" v-for="shareItems in list.list" :key="shareItems.id">   
                <el-card class="share-doc-card" :body-style="{ padding: '0px' }" @click.native="handleRowClickEvent(shareItems)">
                    <div class="document-top">
                        <!-- 图片展示 -->
                        <img src="@/assets/file.png" alt="shareItems.name" lazy>
                    </div>
                    <div class="document-middle">
                        <span style="margin-bottom:10px;" :title="shareItems.name">{{shareItems.name}}</span>
                        <div class="document-middle-other">
                            <span>共享数：{{ shareItems.count}}次</span>
                            <span>阅读数：{{ shareItems.readCount}}次</span>
                        </div>
                        <div class="dowm-select" >
                            <span>{{shareItems.createDate | date}}</span>
                            <el-dropdown>
                                <span class="el-dropdown-link">
                                <i class="el-icon-more el-icon--right" title="更多"></i>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item icon="el-icon-edit" v-show="shareItems.state===0" @click.native="targets(shareItems)">权限修改</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-link" v-show="shareItems.state===0" @click.native="link(shareItems)">授权链</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-turn-off" @click.native="enableMyShare(shareItems)">{{(shareItems.state===0 ?'取消共享':'启用共享')}}</el-dropdown-item>
                                    <el-dropdown-item icon="iconfont icon-ico icon-color" v-show="shareItems.state===0" @click.native="showQR(shareItems.urlShort)">二维码共享</el-dropdown-item>
                                    <el-dropdown-item icon="el-icon-copy-document icon-color" v-show="shareItems.state===0" @click.native="copyUrl(shareItems.urlShort)">链接共享</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </div>
                    </div>
                </el-card>
              </el-col>
          </template>
          <div class="no-data-box" v-else>
              <img src="@/assets/no-file.svg" alt="">
              <span>暂无数据</span>
          </div>
      </el-row>
    </div>
    <div class="pagination" v-if="list.list.length > 0">
      <el-pagination
        class="pagebox"
        @current-change="pageChanged"
        :page-size="pageSize"
        layout="total,  prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
    <el-dialog title="共享二维码" :visible.sync="showQRCode" width="350px" :close-on-click-modal="false" >
      <div style="text-align: center;" class='qrCode-style' >
        <qr :margin="10" :text="shareUrl" :size="200"></qr>
        <p>请扫描二维码查看共享信息</p> 
      </div>
      
    </el-dialog>
    <ShareManager ref="user" />
    <FileChain ref="chain" />
    <!-- <ShareDialog ref="shareDialog"></ShareDialog> -->
    <ShareDialogs ref="shareDialogs"></ShareDialogs>
    <ShareEdit ref="shareEditPage"></ShareEdit>
  </div>
</template>
<script>
  import qr from 'vue-qr'
  //TODO 使用资源管理，共享模块也有一个目标管理，ShareUser.vue
  import ShareManager from '../files/ShareManager.vue'
  import FileChain from '../files/FileChain.vue'
  // import ShareDialog from './ShareForm.vue'
  import ShareDialogs from '../liankong/components/ShareDialog.vue'
  import ShareEdit from '../liankong/components/ShareEditdialog.vue'
  export default {
    data() {
      return {
        dateTime: '', // 时间
        query: {
          end: '',
          start: '',
          name: null,
          pageNum: 0,
          pageSize: 10
        },
        showQRCode: false,
        submitLoading: false,
        shareUrl: null,
        loading: false,
        favoriteTabNum: 1
      }
    },
    components: { 
      qr, 
      ShareManager, 
      FileChain, 
      // ShareDialog 
      ShareDialogs,
      ShareEdit
    },
    computed: {
      list: function () {
        return this.$store.state.shares.views.sender || []
      },
      pageSize: function () {
        return this.$store.state.shares.views.sender.query.pageSize || 0
      },
      total: function () {
        return this.$store.state.shares.views.sender.paging.total || 0
      }
    },
    methods: {
      checkEndTile(startTime,endTime){
        let currentTime = new Date();
        if(startTime && endTime){
          // 有开始时间和结束时间
          let start = new Date(startTime);
          let end = new Date(endTime);
          if(currentTime.getTime() <  start.getTime()){
            return '未开始'
          }else {
            if(currentTime.getTime() <  end.getTime()){
              return this.timestampToTime(end.getTime() - currentTime.getTime() )
            }else{
              return '0天'
            }
          }
        }else if(!startTime && !endTime){
          // 都没有
          return '永久'
        }else if(!startTime && endTime){
          // 没有开始时间，有结束时间
          let end = new Date(endTime);
          // 如果当前时间小于结束时间则显示剩余时间，如果大于或者等于显示0天
          if(currentTime.getTime() <  end.getTime()){
            return this.timestampToTime(end.getTime() - currentTime.getTime() )
          }else{
            return '0天'
          }
          
        }else if(startTime && !endTime){
          // 有开始时间 ，没有结束时间
          let start = new Date(startTime);
          // 如果当前时间小于开始时间显示未开始，否则显示永久
          if(currentTime.getTime() <  start.getTime()){
            return '未开始'
          }else{
            return '永久'
          }
        }
      },
      timestampToTime(timestamp) {
        var s = parseInt(timestamp / 1000 % 60)//秒 余下多少秒（0-59）
        var m = parseInt(timestamp / 1000 / 60 % 60); //余下了多少分钟（0-59）
        var h = parseInt(timestamp / 1000 / 60 / 60 % 24);//余下多少时（0-23）

        //获取多少天
        var t = parseInt(timestamp / 1000 / 60 / 60 / 24);
        return t + "天" + h + "小时" + m + "分钟" + s + "秒";
      },
      handleRowClickEvent(row,type){
        if(row.state===3){
          this.$message.warning('该共享已关闭')
          return
        }
           this.$store.dispatch("auth/loadShare", row.id).then(data => {
            let { resources } = this.$store.state.auth.editShare;
            if(resources.length == 1){
              if(resources[0].type == 1){
                this.$nextTick(()=>{
                  if( this.$refs.shareDialogs){
                    this.$refs.shareDialogs.render(resources[0]);
                  }
                })
              }else{
                this.$refs.shareDialogs.dialogVisible = true;
                this.$nextTick(()=>{
                  this.$refs.shareDialogs.onLoadInit(row,true);
                })
              }
            }else{
              this.$refs.shareDialogs.dialogVisible = true;
              this.$nextTick(()=>{
                this.$refs.shareDialogs.onLoadInit(row,true);
              })
            }  
          })

        
      },
      // 时间搜索
      changeData() {
        let {start,end} = this.query
        this.pageChanged(1)
      },
      // 二次共享
      addShare(data) {
        let row = null;

        //兼容行内button
        if (data instanceof Object) {
          row = data;
        } else {
          row = this.$store.state.app.contextMenuClientEvent.data;
        }

        const shareFiles = [row];

        if (this.$store.state.document.viewer.selection) {
          this.$store.state.document.viewer.selectionRows.map((item) => {

            if (item.id != row.id) {
              shareFiles.push(item);
            }
          });
        }
        /* this.$refs["shareDialog"].show({
          mode: "new",
          value: shareFiles,
        }); */
      },
      // 跳转详情页
      goDetail(item) {
        if(item.state===3){
          this.$message.warning('该共享已关闭')
          return
        }
        let queryId = {
          id: item.id
        }
        this.$router.replace({ 
          // path: "/shares/detail",
          path: '/sharetion/detail',
          query: queryId
        });
      },
      // 分页
      pageChanged(num) {
        let that = this
        let {start,end} = this.query
        this.$store.commit('shares/updateQuery', {
          vkey: 'sender',
          query: {
            pageNum: num,
            start: start ? start : '',
            end: end ? end : ''
          }
        })
        this.queryList(true, false)
      },
      onSubmit() {
        this.queryList()
      },
      // 数据列表
      queryList(reset, flag) {
        this.loading = true
        this.$store.dispatch("shares/myShareList", {
          reset: reset ? true : false,
          noReset: flag ? true : false
        }).then(data => {
          this.loading = false
        })
      },
      // 赋值链接地址
      copyUrl(shareId) {
        this.$copyText(this.$options.filters["shareUrl"](shareId)).then(e => {
          this.$message({
            message: '复制成功,快去共享给好友吧',
            type: 'success'
          })
        }, e => {
          this.$message({
            message: '复制失败，请手动复制分析连接',
            type: 'error'
          })
        })
      },
      // 显示二维码
      showQR(shareId) {

        this.shareUrl = this.$options.filters["shareUrl"](shareId)
        this.showQRCode = true
      },
      // 展示授权链
      link(shareId) {
        this.$refs['chain'].show("share", shareId)
      },
      remove(shareId) {
        this.submitLoading = true;
        this.$store.dispatch("shares/remove", shareId).then(({ code, data, message }) => {
          this.submitLoading = false;
          this.queryList()
        })
      },
      // 权限编辑
      targets(shareId) {
        // let queryId = {
        //   id: shareId.id
        // }
        // this.$refs["user"].show(shareId)
      //  this.$router.replace({ path: "/shares/edit", query: queryId});
        // this.$router.replace({ path: "/sharetion/edit", query: queryId});
        this.$refs.shareEditPage.dialogVisible = true;
        this.$nextTick(()=>{
          this.$refs.shareEditPage.onLoadEditInit(shareId)
        })
      },
      // 启用禁用共享
      enableMyShare(row) {
        let clise = this.$loading({
          lock: true,
          text: '操作中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        this.$store.dispatch(`share/${row.state === 0 ? 'disable' : 'enable'}`, row.id)
          .then(res => {
            if(res.code == 0){
              this.$message.success('操作成功')
            }else{
              this.$message.error(res.message);
            }
            clise.close()
            
          })
      }
    },
    created() {
      let {start,end} = this.$store.state.shares.views.sender.dateTime
      this.query.start = start
      this.query.end = end
      this.queryList(true, true)
    },
    mounted(){
      this.$store.dispatch("doc/loadChildren", {
            vkey: 'addShareFile',
            reset: true,
      })
      this.$ebus.$on('favoriteSearch', name=>{
          let querys = this.$store.state.shares.views.sender.query;
          querys.name = name;
          this.query.name = name;
          this.queryList(true, true)
      })
      this.$ebus.$on('clearSearch', vals=>{
          let querys = this.$store.state.shares.views.sender.query;
          querys.name = '';
          this.query.name = ''
          this.queryList(true, true)
      })
      this.$ebus.$on('favoriteTabEvent', favoriteTabNum=>{
          this.favoriteTabNum = favoriteTabNum;
      })
    },
    beforeDestroy(){
      // this.$store.commit("shares/updateDate", {
      //   vkey:'sender',
      //   query:this.query
      // })
      this.$ebus.$off('favoriteSearch');
      this.$ebus.$off('clearSearch');
      this.$ebus.$off('favoriteTabEvent');
    }
  }
</script>

<style lang="less" scoped>
.el-icon-back{
  font-size: 24px;
  margin-top: 10px;
}
  .page {
    // padding: 10px 10px;
    box-sizing: border-box;

    .files-table {
      margin: 0;

      .table-search {
        width: 100%;
        // height: 50px;
        display: flex;
        justify-content: flex-start;
        // border-bottom: 1px solid #c6cad636;
        margin-bottom: 20px;
      }
      .el-row {
        height: calc(100vh - 265px);
        overflow: auto;
        .el-col-5 {
          width: 19.83%;
        }
      }
      /deep/ .el-table {
        .file-box {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            cursor: pointer;
            img {
                margin-right: 10px;
                vertical-align: middle;
                margin-bottom: 3px;
            }
            span {
                display: block;
                width: 240px;
                height: 25px;
                overflow: hidden;
                text-overflow: ellipsis;
                // display: -webkit-box;
                // -webkit-line-clamp: 1;
                white-space: nowrap;
                // -webkit-box-orient: vertical;
            }
        }
        .el-table__header-wrapper {
              .has-gutter {
                  tr th{
                      border:none;
                      background: #F2F2F2;
                      color: #666;
                  }
              }
          }
        .el-table__body-wrapper {
          .el-table__body {
            .el-table__row {
              td {
                border:none !important;
                padding: 12px 0;
                .share-share {
                  display: flex;
                  list-style: none;
                  margin: 10px 0 0;
                  padding: 0px;

                  .share-line {
                    padding: 0 10px;
                    box-sizing: border-box;
                  }
                }

                .share-read {
                  justify-content: space-around;
                  margin: 0px;
                }

                .icon-color {
                  padding: 5px;
                  box-sizing: border-box;
                  color: black;
                  border: 1px solid #dddddd;
                  border-radius: 15px;
                }
              }
            }
          }
        }
          
        &::before {
            height: 0px;
        }
      }

      .file-column-name {
        display: flex;
        justify-content: space-between;
        margin-right: 50px;

        a {
          color: initial;
          text-decoration: none;
        }

        .btns {
          font-size: 16px;

          i {
            display: inline-block;
            padding: 5px;
            margin: 0 2px;
            cursor: pointer;
          }
        }

      }

      /deep/ .el-table__row {
        // background: red;
        td:first-child {
          // font-size: 24px;
          // vertical-align: top !important;
        }

        td:last-child .cell {
          // margin-top: 55px;

          .el-button--medium {
            i {
              font-size: 20px;
            }
          }
        }
      }
    }

    .pagebox {
      text-align: left;
      margin: 20px 0 0 0;
    }
    .share-doc-card {
        height: 220px;
        padding: 10px;
        box-sizing: border-box;
        .document-top {
            height: 100px;
            line-height: 100px;
            text-align: center;
            img{
              margin-top: 10px;
            }
            
        }
        .document-middle {
            margin: 10px;
            .document-middle-other{
              color: #666;
              font-size: 14px;
              margin: 6px 0;
            }
            >span {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                word-break: break-all;
                margin-bottom: 5px;
            }
            div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                .el-icon-star-on {
                    color: #409EFF;
                    font-size: 16px;
                }
            }
            .dowm-select {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 7px;
                span{
                  color: #999;
                  font-size: 12px;
                }
                .el-dropdown {
                    .el-icon-more{
                        color: #409EFF;
                    }
                }
            }
        }
    }
    .no-data-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2%;
        margin-right: 10%;
        img {
            width: 380px;
            height: 280px;
        }
        span {
            color: #bdc1cd;
            font-size: 16px;
            margin-top: 15px;
            font-weight: 100px;
        }
    }
  }
</style>