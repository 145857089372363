<template>
    <div>
        <ShareToMe></ShareToMe>
    </div>
</template>
<script>
import ShareToMe from "../files/ShareToMe.vue";
export default {
    data(){
        return{

        }
    },
    components: {
        ShareToMe
    }
}
</script>