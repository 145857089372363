<template>
  <el-dialog
    title="选择授权目标"
    :visible.sync="visible"
    :close-on-click-modal="false" 
    width="400px"
    top="20vh"
    append-to-body>
    <UserSearch @onUserSelected="onUserSelected"></UserSearch>
  </el-dialog>
</template>

<script>
import UserSearch from '@/components/UserSearch.vue'

export default {
  components: { UserSearch },
  data () {
    return {
      visible: false
    }
  },
  computed: {},
  watch: {},
  methods: {
    onUserSelected(value){
      this.$emit("onUserSelected", value)
      this.visible = false
    }
  },
  created () {

  },
  mounted () {

  },
}
</script>
<style lang='less' scoped>

</style>