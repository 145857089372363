<template>
    <div>
        <div class="share-doc-headtab">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="他人共享" name="first"></el-tab-pane>
                <el-tab-pane label="我共享的" name="second"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="share-doc-form">
             <el-form @submit.native.prevent :inline="true" :model="query" class="demo-form-inline">
                <el-form-item label="文件名称">
                    <el-input @keyup.enter.native="searchBtn" v-model="query.name" clearable placeholder="请输入搜索关键字" size="small" @clear="clearSearch"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="small" icon="el-icon-search" @click="searchBtn">搜索</el-button>
                </el-form-item>
                <el-form-item>
                    <i :style="{'color': favoriteTabNum == 1 ? '#409eFF' : ''}" class="el-icon-set-up icons" @click="tabViewerPage(1)"></i>
                    <i :style="{'color': favoriteTabNum == 2 ? '#409eFF' : ''}" class="el-icon-menu icons" @click="tabViewerPage(2)"></i>
                </el-form-item>
            </el-form>
        </div>
        
        <div v-if="activeName == 'first'">
            <ShareOther></ShareOther>
        </div>
        <div v-else>
            <MyShare></MyShare>
        </div>
    </div>
</template>
<script>
import ShareOther from '@/views/liankong/OtherShare.vue'
import MyShare from '@/views/share/SharePage.vue'
export default {
    data() {
        return {
            activeName: 'first',
            favoriteTabNum: 1,
            query: {
                name: ''
            }
        };
    },
    components: {
        ShareOther,
        MyShare
    },
    mounted() {
        if(this.$route.query.active){
            this.activeName = this.$route.query.active
        } else {
            this.activeName = 'first'
        }
    },
    methods: {
      handleClick(tab, event) {
        this.activeName = tab.name;
      },
      // 搜索
      searchBtn() {
        this.$ebus.$emit('favoriteSearch', this.query.name)
      },
      // 清空
      clearSearch() {
        this.$ebus.$emit('clearSearch')
      },
      // 切换视图
      tabViewerPage(num) {
        this.favoriteTabNum = num;
        this.$ebus.$emit('favoriteTabEvent', num)
      }
    }
};
</script>
<style lang="less" scoped>
/deep/ .main-bread-heads {
    height: 0px !important;
}
.share-doc-headtab{
    display: flex;
    align-items: center;
    /deep/ .el-tabs.el-tabs--top {
        width: 100%;
        .el-tabs__item {
            font-size: 16px;
        }
        .is-active {
            color: #303133;
            font-weight: bold;
        }
        .el-tabs__active-bar {
            height: 3px;
            background: #303133;
        }
        .el-tabs__nav-wrap::after{
            height: 1px;
        }
        .el-tabs__header {
            margin: 0px;
        }
    }
}
.share-doc-form {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
    /deep/ .el-form {
        .el-form-item {
            margin-bottom: 0px;
            .icons {
                font-size: 20px;
                margin-top: 10px;
            }
            .icons:nth-child(1) {
                margin-right: 5px;
            }
        }
    }
}
</style>